import ItodoImage from "../../../elements/itodo-img";
function SectionAboutUs5() {
  return (
    <>
      <div className="section-full  p-t0 p-b80 sx-bg-white sx-about-bx1-outer">
        <div className="container g-5">
          <div className="sx-r-part-image-content-box">
            <div className="row">
              <div className="col-lg-6 col-md-12 p-4">
                <div className="sx-r-part-content">
                  <h2 className="sx-title">
                    Track Record of Success
                  </h2>
                  <p>
                    TechPort has successfully completed projects for over 100 companies in Los Angeles, accumulating more than 500 projects 
                    in total. We boast a client return rate of over 50% and a commendation rate of 97%, reflecting our commitment to customer satisfaction. 
                  </p>
                  <div className="progress">
                      <div className="progress-bar sx-bg-primary" role="progressbar" style={{ width: '97%' }} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100}><span>97% Client Satisfaction</span></div>
                  </div>
                  <h2 className="sx-title">
                    Technological Adaptation
                  </h2>
                  <p>
                      As technology evolves, so do our tools and methods. We utilize the latest technologies capable of integrating with 
                      most platforms or third-party services, enhancing both flexibility and security. Furthermore, we continually expand 
                      our expertise to meet future demands and stay ahead in the industry.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 p-4">
                <div className="sx-r-part-media">
                  <ItodoImage src="images/portfolio/pic1.jpg" alt="image" />
                </div>
              </div>
            </div>
          </div>
          <div className="sx-r-part-image-content-box">
            <div className="row">
              <div className="col-lg-6 col-md-12 p-4">
                <div className="sx-r-part-media">
                  <ItodoImage src="images/portfolio/pic2.jpg" alt="image" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 p-4">
                <div className="sx-r-part-content">
                  <h2 className="sx-title">
                    Our Expert Team
                  </h2>
                  <p>
                    Our multidisciplinary team comprises software engineers, UI/UX designers, product managers, data analysts, and cybersecurity experts. 
                    This diverse talent pool ensures we can optimize every project to its fullest potential while minimizing costs for our clients.
                  </p>
                  <h2 className="sx-title">Future Plan</h2>
                  <p>
                      Looking ahead, our team plans to integrate AI into everyday marketing operations to reduce labor costs and increase data accuracy. 
                      We are currently developing a system that will automatically extract data from emails and generate documents tailored to user needs, 
                      leveraging AI to enhance efficiency and precision in communications.
                  </p>
                  <div className="progress">
                      <div className="progress-bar sx-bg-primary" role="progressbar" style={{ width: '75%' }} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100}><span>Served more than a dozen industries</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SectionAboutUs5;
