import ItodoImage from "../../../elements/itodo-img";

function GeneralFAQContent() {
    return (
        <>
            <div className="aon-faq-content">
                <div className="row">
                    <div className="col-lg-7 col-md-12">
                        <div className="sx-acdn-style-1-section">
                            <div className="accordion sx-acdn-style-1" id="sf-faq-accordion">
                                {/*One*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ1" aria-expanded="false">
                                        What types of customers do you serve?
                                    </button>
                                    <div id="FAQ1" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
                                            We serve a diverse range of clients and companies, from small businesses to large enterprises across various sectors including real estate, education, community organizations, 
                                            and corporate entities. If you are a startup, we are particularly eager to share our expertise with you. We'll work closely with you to build the project you envision, providing 
                                            hands-on guidance every step of the way.
                                        </div>
                                    </div>
                                </div>
                                {/*Two*/}
                                <div className="accordion-item">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ2" aria-expanded="true">
                                        What technologies do you work with?
                                    </button>
                                    <div id="FAQ2" className="accordion-collapse collapse show" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
                                            <span>At TechPort, our team is proficient in a wide array of technologies tailored to meet diverse development needs.</span>
                                            <br/>
                                            <br/>
                                            <div>
                                                <b>Our expertise includes programming languages such as </b>
                                            </div>
                                            <div>
                                                Python, Java, C#, C++, and PHP, as well as Golang, JavaScript, React Native, Swift, and Kotlin. along with their 
                                                corresponding frameworks.
                                            </div>
                                            <br/>
                                            <div>
                                                <b>We are also skilled in using major cloud platforms like</b>
                                            </div>
                                            <div>
                                                Azure, AWS, and GCP, and big data technologies including Kafka, Spark, and Hadoop.
                                            </div>
                                            <br/>
                                            <div>
                                                <b>Database management: </b>
                                            </div>
                                            <div>
                                                both SQL and NoSQL systems
                                            </div>
                                            <br/>
                                            <div>
                                                This broad technological base enables us to deliver cutting-edge solutions for any project requirement.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*Three*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ3" aria-expanded="false">
										How do you ensure the quality of your services?？
                                    </button>
                                    <div id="FAQ3" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
											At TechPort, we use the latest code standards and technologies, which not only improves compatibility between systems but also enhances security. 
											Additionally, <b>after each project completion</b> (excluding maintenance), <b>TechPort offers 3 to 12 months of free maintenance service</b>, 
											depending on the project size. This guarantee ensures that all projects operate as expected, preventing any issues or conflicts 
											and ensuring smooth functionality.
                                        </div>
                                    </div>
                                </div>
                                {/*Four*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ4" aria-expanded="false">
                                        How much would it cost for a project
                                    </button>
                                    <div id="FAQ4" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
											The cost of a project at TechPort is determined by its scale and complexity. For instance, <b>a basic e-commerce website</b> built using <b>Shopify or WordPress</b> 
											starts at just over <b>$2000</b>. We are committed to delivering exceptional projects while adhering to our clients' budgets. Our outstanding value for money 
											is one of the key reasons we enjoy a high rate of customer return.
                                        </div>
                                    </div>
                                </div>
                                {/*Five*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ5" aria-expanded="false">
										Do you offer project estimates for free?
                                    </button>
                                    <div id="FAQ5" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
										Yes, we offer free project estimates as part of our commitment to transparency and client satisfaction.
                                    </div>
                                </div>
                                {/*Six*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ6" aria-expanded="false">
										Do You offer maintenance services for projects not originally developed by TechPort?
                                    </button>
                                    <div id="FAQ6" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
											Yes, TechPort provides maintenance services for all projects, regardless of whether they were initially developed by us. 
											For specific maintenance pricing, please contact us directly through our "Contact Us" page.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12">
                        <div className="aon-faq-1-media">
                            <ItodoImage src="images/faq-img-1.jpg" alt="#" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function HelpFAQContent() {
    return (
        <>
            <div className="aon-faq-content">
                <div className="row">
                    <div className="col-lg-7 col-md-12">
                        <div className="sx-acdn-style-1-section">
                            <div className="accordion sx-acdn-style-1" id="sf-faq-accordion-1">
                                {/*One*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ1-1" aria-expanded="false">
                                    How do you handle our intellectual property? Who owns the code?
                                    </button>
                                    <div id="FAQ1-1" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion-1">
                                        <div className="accordion-body">
                                            Before our first project discussion, we can sign a <b>non-disclosure agreement (NDA)</b>. We understand the value of your ideas and innovations and are committed to protecting them.
                                            Moving forward, our <b>Master Service Agreements (MSAs)</b> guarantee that you retain legal ownership of all intellectual properties, including the code developed throughout our partnership.
                                        </div>
                                    </div>
                                </div>
                                {/*Two*/}
                                <div className="accordion-item">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ2-1" aria-expanded="true">
                                        I have a project in mind. Who should I contact to discuss it?
                                    </button>
                                    <div id="FAQ2-1" className="accordion-collapse collapse show" data-bs-parent="#sf-faq-accordion-1">
                                        <div className="accordion-body">
                                            Choose the most convenient way to reach us:
                                                
                                        </div>
                                            <ul>
                                                <li><b>Email</b> us at winstonlin0309@gmail.com or use any contact button on our website for a response within <b>1–2 hours</b> to <b>one business day</b> (on holidays and weekends).</li>
                                                <br/>
                                                <li><b>Text to</b> +1(626)-689-3748  for the fastest response. We are available 24/7 (might be slower to respond during night time at PDT period) </li>
                                                <br/>
                                                <li><b>WeChat to</b> 微信号 to start detail communication </li>
                                            </ul>
                                    </div>
                                </div>
                                {/*Three*/}
                                <div className="accordion-item">
								<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ6" aria-expanded="false">
										How do you estimate timelines for new projects?
                                    </button>
                                    <div id="FAQ6" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
											Estimating timelines for new projects begins with defining the project scope, which is crucial for understanding the requirements and objectives. 
											Once the scope is clearly defined, we decompose the project into smaller, manageable tasks. This detailed breakdown allows us to estimate the effort 
											required for each component accurately. By analyzing these tasks, we can identify dependencies, potential bottlenecks, and opportunities for efficiency 
											and get a clearer picture of resource allocation. For small projects, this initial phase of defining the scope, breaking down tasks, and assembling a 
											team could take anywhere from a few days to 1–2 weeks. For medium-sized projects, this phase might extend to 2–4 weeks. For larger projects, especially 
											those with significant complexity or multiple phases, the initial planning phase could take 1–2 months. These projects often require extensive coordination 
											among teams and a thorough understanding of dependencies and risks.
                                        </div>
                                    </div>
                                </div>
                                {/*Four*/}
                                <div className="accordion-item">
								<button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ3" aria-expanded="false">
                                        Can you join our project at any stage?
                                    </button>
                                    <div id="FAQ3" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion">
                                        <div className="accordion-body">
                                            Yes, we can join your project at any stage, be it early design, ongoing development, or post-launch support. Our experts are trained to quickly align with your project's goals, workflows, and tools, 
                                            aking the transition as smooth and effective as possible.
                                        </div>
                                    </div>
                                </div>
                                {/*Five*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ5-1" aria-expanded="false">
										Can you take over a project from a different IT vendor?
                                    </button>
                                    <div id="FAQ5-1" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion-1">
                                        <div className="accordion-body">
											Absolutely, we can take over projects from other IT vendors. Simply provide us with the relevant project information, such as requirement specifications, architectural diagrams, and any other pertinent details, 
											and we can seamlessly assume responsibility for your project.
                                        </div>
                                    </div>
                                </div>
                                {/*Six*/}
                                <div className="accordion-item">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#FAQ6-1" aria-expanded="false">
										How does the project onboarding process usually look like at TechPort?
                                    </button>
                                    <div id="FAQ6-1" className="accordion-collapse collapse" data-bs-parent="#sf-faq-accordion-1">
                                        <div className="accordion-body">
											At TechPort, we initiate the onboarding process by thoroughly understanding your specific requirements. This initial step is followed by creating a comprehensive project plan that outlines all critical milestones and 
											deliverables. If necessary, a dedicated project manager is assigned to ensure seamless communication and coordination. We also integrate seamlessly with your existing development processes and tools to maintain continuity. 
											To ensure transparency and alignment throughout the project, we establish a robust communication plan that includes regular updates and access to shared dashboards. This structured approach guarantees that every project phase is executed efficiently and meets all your expectations.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12">
                        <div className="aon-faq-1-media">
                            <ItodoImage src="images/faq-img-2.jpg" alt="#" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function SectionFAQContent() {
    return (
        <>
            <div className="tab-content">
                {/*1*/}
                <div className="tab-pane fade show active" id="day_1" role="tabpanel">
                    <GeneralFAQContent />
                </div>
                {/*2*/}
                <div className="tab-pane fade" id="day_2" role="tabpanel">
                    <HelpFAQContent />
                </div>
            </div>
        </>
    )
}

export default SectionFAQContent;