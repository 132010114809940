import SectionVideo2 from "../../../sections/home/index2/section-video2"
import SectionWhyChooseUs4 from "../../../sections/company/about/section-why-choose-us4"
import SectionClients2 from "../../../sections/home/index2/section-clients2"
import SectionCounter1 from "../../../sections/home/index/section-counter1";
import ItodoImage from "../../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../../globals/constants";

function ServicesDetailPage() {

    useEffect(()=>{
        loadScript("js/custom.js")
    })

    return (
        <>
            {/* service detail SECTION START */}
            <div className="section-full p-b80 sx-ser-detail-outer sx-bg-white">
                <div className="sx-ser-detail-l-pic p-b80">
                    <div className="sx-media  relative ">
                        <ItodoImage src="images/services/service-detail.jpg" alt="image" />
                        <div className="sx-bnr-outline-text">
                            <h1> Enterprise Digitalization</h1>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="section-content">
                        <div className="sx-our-service-detail">
                            {/* TITLE START */}
                            <div className="section-head2">
                                <div className="sx-head-s-title">Accelerate Your Business Transformation with TechPort's Enterprise Digitalization Services</div>
                                <div className="sx-head-l-title">
                                    <h2 className="sx-title2">Enterprise Digitalization</h2>
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="sx-our-service-info m-b80">
                                <p>
                                    TechPort's Enterprise Digitalization services are designed to modernize and transform your business by integrating advanced digital 
                                    technologies into all areas of your enterprise. We focus on automating processes, enhancing data analytics, and improving digital 
                                    communication channels to ensure that your business not only survives but thrives in the digital age.
                                </p>
                                <h5>Key Features of TechPort’s Enterprise Digitalization Services:</h5>
                                <ul>
                                    <li>
                                        Digital Process Automation: Streamline and automate business processes to increase efficiency and reduce operational costs.
                                    </li>
                                    <li>
                                        Advanced Data Analytics: Leverage big data and advanced analytics to gain deep insights into market trends, customer behavior, and operational efficiency.
                                    </li>
                                    <li>
                                        Digital Customer Experience: Enhance the customer experience through tailored digital interactions that boost engagement and satisfaction.
                                    </li>
                                    <li>
                                        Cloud Solutions: Implement cloud-based infrastructures that offer scalability, flexibility, and enhanced security for all your business operations.
                                    </li>
                                    <li>
                                        Cybersecurity Enhancements: Strengthen your digital defenses with state-of-the-art cybersecurity measures to protect sensitive data and maintain trust.
                                    </li>
                                </ul>
                                <div className="row sx-about-icon-bx1-column p-t50 p-b20">
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-consultant" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Consulting</span>
                                                <span >Understand business processes, pain points, and goals through deep discussions and site assessments.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-supply-chain" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Solution Proposal</span>
                                                <span>Draft a detailed project proposal including technical architecture, budget, timeline, and scope based on initial assessments.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-web-programming" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Design Templates</span>
                                                <span>Design UI/UX, creating wireframes and prototypes that are both user-friendly and functional.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-software-development" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Development</span>
                                                <span>Begin coding and development based on the defined requirements and design documents using agile methodologies.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-security" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">System Testing</span>
                                                <span>Conduct comprehensive testing including unit, integration, performance, and user acceptance tests to ensure system reliability and compliance.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-development" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">System Handover</span>
                                                <span>Deploy the system, train users, and provide necessary documentation for effective operation and troubleshooting.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>
                                    With TechPort at the helm of your digital transformation, your business can achieve a significant competitive edge by leveraging technology 
                                    to streamline operations, enhance customer experiences, and optimize overall performance.
                                </p>

                            </div>
                            
                            <SectionCounter1 />
                        </div>
                    </div>
                </div>
            </div>
            {/* service detail SECTION END */}
            
            <SectionVideo2 />
            <SectionWhyChooseUs4 />
            <SectionClients2 />
        </>
    )
}

export default ServicesDetailPage;