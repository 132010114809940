import SectionVideo2 from "../../../sections/home/index2/section-video2"
import SectionWhyChooseUs4 from "../../../sections/company/about/section-why-choose-us4"
import SectionClients2 from "../../../sections/home/index2/section-clients2"
import SectionCounter1 from "../../../sections/home/index/section-counter1";
import ItodoImage from "../../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../../globals/constants";

function ServicesDetailPage() {

    useEffect(()=>{
        loadScript("js/custom.js")
    })

    return (
        <>
            {/* service detail SECTION START */}
            <div className="section-full p-b80 sx-ser-detail-outer sx-bg-white">
                <div className="sx-ser-detail-l-pic p-b80">
                    <div className="sx-media  relative ">
                        <ItodoImage src="images/services/service-detail.jpg" alt="image" />
                        <div className="sx-bnr-outline-text">
                            <h1> ERP/CRM System</h1>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="section-content">
                        <div className="sx-our-service-detail">
                            {/* TITLE START */}
                            <div className="section-head2">
                                <div className="sx-head-s-title">Enhance Your Business Operations with Custom ERP and CRM Solutions from TechPort</div>
                                <div className="sx-head-l-title">
                                    <h2 className="sx-title2">ERP/CRM System</h2>
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="sx-our-service-info m-b80">
                                <p>
                                    At TechPort, we deliver custom-built Enterprise Resource Planning (ERP) and Customer Relationship Management (CRM) systems 
                                    as part of our extensive service range, designed to improve business efficiency and customer engagement. Each solution is 
                                    crafted to meet the unique requirements of our clients, ensuring an ideal fit for their specific business needs.
                                </p>
                                <p>
                                    Custom ERP Solutions: We tailor ERP systems to seamlessly integrate and streamline your specific business processes, 
                                    including finance, human resources, and supply chain management. Our bespoke solutions offer critical insights into your 
                                    operations, aiding effective resource management and informed decision-making.
                                </p>
                                <p>
                                    Custom CRM Solutions: Developed to manage all aspects of customer interactions and data, our custom CRM systems enhance 
                                    relationship management and service delivery. By understanding your unique business environment, we create CRM systems that 
                                    enhance customer attraction, retention, and sales growth.
                                </p>
                                <div className="row sx-about-icon-bx1-column p-t50 p-b20">
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-consultant" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Consulting</span>
                                                <span >Understand business processes, pain points, and goals through deep discussions and site assessments.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-supply-chain" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Solution Proposal</span>
                                                <span>Draft a detailed project proposal including technical architecture, budget, timeline, and scope based on initial assessments.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-web-programming" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Design Templates</span>
                                                <span>Design UI/UX, creating wireframes and prototypes that are both user-friendly and functional.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-software-development" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Development</span>
                                                <span>Begin coding and development based on the defined requirements and design documents using agile methodologies.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-security" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">System Testing</span>
                                                <span>Conduct comprehensive testing including unit, integration, performance, and user acceptance tests to ensure system reliability and compliance.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-development" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">System Handover</span>
                                                <span>Deploy the system, train users, and provide necessary documentation for effective operation and troubleshooting.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>
                                    Choose TechPort for strategic tools that are not merely software installations, but essential assets crafted to align with and advance your business 
                                    objectives. Our dedication to customization sets us apart, ensuring each solution perfectly matches your operational needs and market challenges.
                                </p>

                            </div>
                            
                            <SectionCounter1 />
                        </div>
                    </div>
                </div>
            </div>
            {/* service detail SECTION END */}
            
            <SectionVideo2 />
            <SectionWhyChooseUs4 />
            <SectionClients2 />
        </>
    )
}

export default ServicesDetailPage;