import SectionVideo2 from "../../../sections/home/index2/section-video2"
import SectionWhyChooseUs4 from "../../../sections/company/about/section-why-choose-us4"
import SectionClients2 from "../../../sections/home/index2/section-clients2"
import SectionCounter1 from "../../../sections/home/index/section-counter1";
import ItodoImage from "../../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../../globals/constants";

function ServicesDetailPage() {

    useEffect(()=>{
        loadScript("js/custom.js")
    })

    return (
        <>
            {/* service detail SECTION START */}
            <div className="section-full p-b80 sx-ser-detail-outer sx-bg-white">
                <div className="sx-ser-detail-l-pic p-b80">
                    <div className="sx-media  relative ">
                        <ItodoImage src="images/services/service-detail.jpg" alt="image" />
                        <div className="sx-bnr-outline-text">
                            <h1> Customize System Service</h1>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="section-content">
                        <div className="sx-our-service-detail">
                            {/* TITLE START */}
                            <div className="section-head2">
                                <div className="sx-head-s-title">Empower Your Business with TechPort's Custom System Services</div>
                                <div className="sx-head-l-title">
                                    <h2 className="sx-title2">Customize System Service</h2>
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="sx-our-service-info m-b80">
                                <p>
                                    At TechPort, we specialize in developing bespoke systems from the ground up, tailored to the unique needs of 
                                    your business using the latest technologies. Our Custom System Services are designed to provide fully personalized 
                                    solutions that not only meet but exceed your operational requirements and strategic goals.
                                </p>
                                <h5>Key Advantages of TechPort’s Custom System Services:</h5>
                                <ul>
                                    <li>Fully Customized Solutions: We start from zero to build systems that are completely tailored to your specifications, ensuring that every feature and functionality aligns with your business processes.</li>
                                    <li>Latest Technology Integration: Our team stays at the forefront of technological advancements, integrating cutting-edge solutions that enhance efficiency and competitiveness.</li>
                                    <li>Scalability and Flexibility: Our systems are built to be scalable and flexible, adapting to your company’s growth and the ever-changing market conditions.</li>
                                    <li>Long-term Support and Updates: We provide ongoing support and regular updates, ensuring that your custom system evolves with your business and remains effective in the long term.</li>
                                    <li>Competitive Edge: By leveraging a system designed specifically for your needs, you gain a significant competitive advantage in your industry.</li>
                                </ul>
                                <div className="row sx-about-icon-bx1-column p-t50 p-b20">
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-consultant" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Consulting</span>
                                                <span >Understand business processes, pain points, and goals through deep discussions and site assessments.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-supply-chain" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Solution Proposal</span>
                                                <span>Draft a detailed project proposal including technical architecture, budget, timeline, and scope based on initial assessments.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-web-programming" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Design Templates</span>
                                                <span>Design UI/UX, creating wireframes and prototypes that are both user-friendly and functional.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-software-development" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Development</span>
                                                <span>Begin coding and development based on the defined requirements and design documents using agile methodologies.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-security" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">System Testing</span>
                                                <span>Conduct comprehensive testing including unit, integration, performance, and user acceptance tests to ensure system reliability and compliance.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-development" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">System Handover</span>
                                                <span>Deploy the system, train users, and provide necessary documentation for effective operation and troubleshooting.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>
                                    Choose TechPort for custom system development that is as dynamic and innovative as your business. Let us help you transform your operational capabilities and achieve sustained success through tailored technology solutions.
                                </p>

                            </div>
                            
                            <SectionCounter1 />
                        </div>
                    </div>
                </div>
            </div>
            {/* service detail SECTION END */}
            
            <SectionVideo2 />
            <SectionWhyChooseUs4 />
            <SectionClients2 />
        </>
    )
}

export default ServicesDetailPage;