import SectionVideo2 from "../../../sections/home/index2/section-video2"
import SectionWhyChooseUs4 from "../../../sections/company/about/section-why-choose-us4"
import SectionClients2 from "../../../sections/home/index2/section-clients2"
import SectionCounter1 from "../../../sections/home/index/section-counter1";
import ItodoImage from "../../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../../globals/constants";

function ServicesDetailPage() {

    useEffect(()=>{
        loadScript("js/custom.js")
    })

    return (
        <>
            {/* service detail SECTION START */}
            <div className="section-full p-b80 sx-ser-detail-outer sx-bg-white">
                <div className="sx-ser-detail-l-pic p-b80">
                    <div className="sx-media  relative ">
                        <ItodoImage src="images/services/service-detail.jpg" alt="image" />
                        <div className="sx-bnr-outline-text">
                            <h1> Order/Warehouse Management System</h1>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="section-content">
                        <div className="sx-our-service-detail">
                            {/* TITLE START */}
                            <div className="section-head2">
                                <div className="sx-head-s-title">Optimize Your Operations with TechPort's OMS and WMS Services</div>
                                <div className="sx-head-l-title">
                                    <h2 className="sx-title2">OMS/WMS</h2>
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="sx-our-service-info m-b80">
                                <p>
                                    In traditional logistics operations, using Excel to record container data often becomes inefficient as data volumes increase, 
                                    leading to slow loading times and even system crashes. This not only reduces work efficiency but also increases the error rate 
                                    and time costs. TechPort specializes in developing efficient Warehouse Management Systems (WMS) and Order Management Systems (OMS) 
                                    that automate inventory monitoring and order processing to enhance the efficiency and accuracy of logistics operations. Our systems 
                                    are designed to meet the complex demands of the supply chain at every link, ensuring effective global resource management. 
                                    Our WMS/OMS not only reduces manpower but also improves efficiency, minimizes errors, and significantly enhances profitability for businesses.
                                </p>
                                <div className="row sx-about-icon-bx1-column p-t50 p-b20">
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-consultant" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Consulting</span>
                                                <span >Understand business processes, pain points, and goals through deep discussions and site assessments.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-supply-chain" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Solution Proposal</span>
                                                <span>Draft a detailed project proposal including technical architecture, budget, timeline, and scope based on initial assessments.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-web-programming" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Design Templates</span>
                                                <span>Design UI/UX, creating wireframes and prototypes that are both user-friendly and functional.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-software-development" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Development</span>
                                                <span>Begin coding and development based on the defined requirements and design documents using agile methodologies.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-security" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">System Testing</span>
                                                <span>Conduct comprehensive testing including unit, integration, performance, and user acceptance tests to ensure system reliability and compliance.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-development" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">System Handover</span>
                                                <span>Deploy the system, train users, and provide necessary documentation for effective operation and troubleshooting.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>
                                    TechPort's OMS and WMS services are tailored to meet the unique challenges of your business, enhancing your ability to deliver products faster and with greater accuracy.
                                </p>

                            </div>
                            
                            <SectionCounter1 />
                        </div>
                    </div>
                </div>
            </div>
            {/* service detail SECTION END */}
            
            <SectionVideo2 />
            <SectionWhyChooseUs4 />
            <SectionClients2 />
        </>
    )
}

export default ServicesDetailPage;