import SectionVideo2 from "../../../sections/home/index2/section-video2"
import SectionWhyChooseUs4 from "../../../sections/company/about/section-why-choose-us4"
import SectionClients2 from "../../../sections/home/index2/section-clients2"
import SectionCounter1 from "../../../sections/home/index/section-counter1";
import ItodoImage from "../../../elements/itodo-img";
import { useEffect } from "react";
import { loadScript } from "../../../../globals/constants";

function ServicesDetailPage() {

    useEffect(()=>{
        loadScript("js/custom.js")
    })

    return (
        <>
            {/* service detail SECTION START */}
            <div className="section-full p-b80 sx-ser-detail-outer sx-bg-white">
                <div className="sx-ser-detail-l-pic p-b80">
                    <div className="sx-media  relative ">
                        <ItodoImage src="images/services/service-detail.jpg" alt="image" />
                        <div className="sx-bnr-outline-text">
                            <h1> E-Commerce</h1>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="section-content">
                        <div className="sx-our-service-detail">
                            {/* TITLE START */}
                            <div className="section-head2">
                                <div className="sx-head-s-title">Why Choose Uss</div>
                                <div className="sx-head-l-title">
                                    <h2 className="sx-title2">E-Commerce Platform</h2>
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="sx-our-service-info m-b80">
                                <p>
                                    TechPort provides comprehensive e-commerce website development services, covering every step from requirements analysis 
                                    to construction and deployment. Whether you are looking to expand your cross-border e-commerce business or establish a 
                                    brand-exclusive site, we offer the most suitable solutions. Our team leverages the latest technology stack to develop 
                                    custom e-commerce platforms, supporting major frameworks such as Shopify and WordPress. Whether it’s optimizing the performance 
                                    of your existing e-commerce platform or creating a brand-new online store, we ensure innovative, secure, and scalable solutions.
                                </p>
                                <div className="row sx-about-icon-bx1-column p-t50 p-b20">
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-consultant" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Consulting</span>
                                                <span >Start by understanding your business goals and defining project requirements.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-web-programming" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Design & Theme Customization</span>
                                                <span>We create custom designs and tailor themes to align with your brand.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-software-development" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Development & Feature Integration</span>
                                                <span>We implement essential features</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 m-b30">
                                        <div className="sx-icon-bx-2">
                                            <div className="sx-media">
                                                <i className="flaticon-development" />
                                            </div>
                                            <div className="sx-icon-bx-info">
                                                <span className="sx-icon-bx-title">Deliver the Website</span>
                                                <span>We launch the site, ensuring everything is set up correctly.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p>TechPort can also build fully customized systems from scratch if all existing platforms cannot meet your needs.</p>

                            </div>
                            
                            <SectionCounter1 />
                        </div>
                    </div>
                </div>
            </div>
            {/* service detail SECTION END */}
            
            <SectionVideo2 />
            <SectionWhyChooseUs4 />
            <SectionClients2 />
        </>
    )
}

export default ServicesDetailPage;